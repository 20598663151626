/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect } from "react";
import md5 from "md5";
import {decode as atob, encode as btoa} from 'base-64'
import LoginLayout from "../../components/layouts/auth/LoginLayout";
import Swal from 'sweetalert2'
import { useNavigate, Link } from 'react-router-dom';
import StyleLogin from  "./LoginStyle.module.css";

import usersAPI from "../../apiservices/authusersAPI"
import axios from "axios";
import { BASE_URL_PATH,BASE_URL_PATH_AUTH,API_KEY } from "../../apiurl/Api_Url"

// import authusersAPI from "../../../apiservices/authusersAPI"

const Login = () => {
    const baseUrL = process.env.PUBLIC_URL
    //console.log(baseUrL)
    const [users,setusers] = useState([])
    //const [version,setVersion] = useState([])

    /*
    useEffect(()=>{ // document.redy() in jQuery
        //console.log(process.env)
        
        //เรียก API ก่อนเลย เหมือน readData()
        
        // usersAPI.getAllDemo()
        // .then(res=>{
        //     console.log(res.data)
        //     //setusers(res.data)
        // })
        
        //axios.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY;
        axios.get(`${BASE_URL_PATH_AUTH}/version`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res=>{
            const data = JSON.parse(res.data).sqlDataVersion[0]
            //console.log(data.acVS_detail)
            //console.log(res.sqlDataVersion.acVS_version)
            setVersion(data.acVS_version)
        })
    },[])
    */



    //สร้างตัวแปรแบบ State ไว้รับค่าจากฟอร์ม
    const [usernameForm,setusernameForm] = useState(localStorage.getItem('usernameForm'))
    const [passwordForm,setpasswordForm] = useState('')

    //console.log(Boolean(localStorage.getItem('Remember')));
    const [LoginFormRemember,setLoginFormRemember] = useState(localStorage.getItem('LoginFormRemember'))
    let history = useNavigate()


    if(!passwordForm.match(/^([a-z0-9\_])+$/i) && passwordForm !==""){
        setpasswordForm('')
        Swal.fire({
            icon: 'warning',
            title: 'แจ้งเตือน',
            html: 'กรุณากรอกรหัสผ่าน เป็นภาษาอังกฤษเท่านั้น!!',
            confirmButtonText : 'ปิด',
            confirmButtonColor : '#005b85' //
        })
    }
    const inputChecked = (e) =>{
        //console.log(e.target.checked)// ตรวจสอบ value ที่กรอกเข้ามา
        //setLoginFormRemember(e.target.checked)
        // const LoginFormRemember = e.target.checked
        // setLoginFormRemember(LoginFormRemember)
        //console.log(Remember)
        setLoginFormRemember(e.target.checked);
    }

    
    //ฟังชั่นการ submit Form
    const loginForm = async (e) => {
        e.preventDefault()
        //alert('มานี้ๆ');
        if(usernameForm === ""){
            document.getElementById("usernameForm").focus()
            Swal.fire({
                icon: 'warning',
                title: 'แจ้งเตือน',
                html: 'กรุณากรอกหมายเลขบัตรประชาชน',
                confirmButtonText : 'ปิด',
                confirmButtonColor : '#005b85' //
            })
        }else if(passwordForm === ""){
            document.getElementById("passwordForm").focus()
            Swal.fire({
                icon: 'warning',
                title: 'แจ้งเตือน',
                html: 'กรุณากรอกรหัสผ่าน',
                confirmButtonText : 'ปิด',
                confirmButtonColor : '#005b85' //
            })
        }else{
            // console.log(usernameForm)
            // console.log(btoa(btoa(passwordForm)))
            try {
                const userdata = { 
                    username_Form : usernameForm, 
                    password_Form : btoa(btoa(passwordForm))
                };
                document.getElementById('btn_checklogin').style.display = 'none';
                document.getElementById('btn_waitlogin').style.display = ''
                //console.log(userdata)
                //axios.defaults.headers.common['X-API-KEY'] = process.env.REACT_APP_API_KEY;
                //await axios.post(`http://localhost/sakerp_ci3_backend2022/api/login`,JSON.stringify(userdata))
                await axios.post(`${BASE_URL_PATH_AUTH}/login`,JSON.stringify(userdata))
                .then(res=>{
                    //console.log(res.data)
                    //setusers(res.data)
                    if(res.data.status === 901){
                        document.getElementById('btn_checklogin').style.display = '';
                        document.getElementById('btn_waitlogin').style.display = 'none'
                        Swal.fire({
                            icon: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            html: res.data.message,
                            confirmButtonText : 'ปิด',
                            confirmButtonColor : '#005b85' //
                        })
                    }else if(res.data.status === 902){
                        document.getElementById('btn_checklogin').style.display = '';
                        document.getElementById('btn_waitlogin').style.display = 'none'
                        Swal.fire({
                            icon: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            html: res.data.message,
                            confirmButtonText : 'ปิด',
                            confirmButtonColor : '#005b85' //
                        })
                    }else if(res.data.status === 903){
                        document.getElementById('btn_checklogin').style.display = '';
                        document.getElementById('btn_waitlogin').style.display = 'none'
                        Swal.fire({
                            icon: 'error',
                            title: 'เกิดข้อผิดพลาด',
                            html: res.data.message,
                            confirmButtonText : 'ปิด',
                            confirmButtonColor : '#005b85' //
                        })
                    }else{
                        //console.log(res.data)
                        if(LoginFormRemember){
                            //console.log('เป็นจริง')
                            localStorage.setItem('usernameForm', usernameForm)
                            localStorage.setItem('LoginFormRemember',true)
                        }else{
                            //console.log('ไม่จริง')
                            localStorage.setItem('usernameForm', '')
                            localStorage.setItem('LoginFormRemember','')
                        }

                        // เก็บชื่อผู้ใช้ลง LocalStorage
                        localStorage.setItem('userToken',res.data)
                        localStorage.setItem('usernameForm', usernameForm)
                        localStorage.setItem('LoginFormRemember',LoginFormRemember)

                        // ส่งไปหน้า Backend / Dashboard
                        window.location = '/backend/dashboard'
                        //history('/backend/dashboard')
                        

                        // const rememberMe = localStorage.getItem('LoginFormRemember') === LoginFormRemember;
                        // const usernameForm = rememberMe ? localStorage.getItem('usernameForm') : '';
                    

                        // setusernameForm(usernameForm)
                        // setusernameForm(LoginFormRemember)

                        
                        
                
                        //alert('เข้าระบบได้');
                        /*
                        let timerInterval
                        Swal.fire({
                            html: 'กำลังเข้าสู่ระบบ <b></b>',
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                timerInterval = setInterval(() => {
                                const content = Swal.getContent()
                                if (content) {
                                    const b = content.querySelector('b')
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft()
                                    }
                                }
                                }, 2000)
                            },
                            willClose: () => {
                                clearInterval(timerInterval)
                            }
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.timer) {

                                // localStorage.setItem('username', usernameForm)

                                //console.log(LoginFormRemember)
                                if(LoginFormRemember){
                                    //console.log('เป็นจริง')
                                    localStorage.setItem('usernameForm', usernameForm)
                                    localStorage.setItem('LoginFormRemember',true)
                                }else{
                                    //console.log('ไม่จริง')
                                    localStorage.setItem('usernameForm', '')
                                    localStorage.setItem('LoginFormRemember','')
                                }

                                
                                history('/backend/dashboard')
                                
                                //window.location = '/backend/dashboard'
                                
                                
                                
                            }
                        })
                        */
                    }
             
                })
            } catch (err) {
                console.log(err);
            }
            


            
            // console.log(usernameForm)
            // console.log(passwordForm)
            // console.log(LoginFormRemember)
            /*
            if(usernameForm === 'admin' && passwordForm === '123456'){
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: 'Username หรือ Password ไม่ถูกต้อง',
                    confirmButtonText : 'ปิด',
                    confirmButtonColor : '#005b85' //
                })
            }
            */
        }
    }
    
    return (
        <LoginLayout>
        <img className={StyleLogin.wave} src={`${baseUrL}/assets/img/wave.png`} alt=""></img>
            <div className={StyleLogin.container}>
                <div className={StyleLogin.img}>
                <img src={`${baseUrL}/assets/img/undraw_pie_graph_x9dy.svg`} alt="" />
                </div>
                <div className={StyleLogin.login_content}>
                    <div className="row">
                        <div className="col-md-12">
                        <form method="post" onSubmit={loginForm}>
                            <div className="row">
                                <div className="col-md-12">
                                    <img src={`${baseUrL}/assets/img/img_icon/logo saksiam59(150x150px).png`} alt=""/>
                                    <h3 className="title font-weight-bold mt-3">
                                        Login เข้าสู่ระบบ
                                    </h3>
                                    {/* Material input */}
                                    <div className="md-form input-with-post-icon">
                                        <i className="fas fa-user fa-lg input-prefix" />
                                        <input type="text" className={`${StyleLogin.input_text } form-control`} id="usernameForm" name="usernameForm" onChange={(e)=>setusernameForm(e.target.value)} value={usernameForm} maxLength="13"/>
                                        <label htmlFor="usernameForm" className={usernameForm ? 'active' : ''}><h5>หมายเลขบัตรประชาชน</h5></label>
                                    </div>
                                    <div className="md-form input-with-post-icon">
                                        <i className="fas fa-lock fa-lg input-prefix" />
                                        <input type="password" className={`${StyleLogin.input_text } form-control`} id="passwordForm" name="passwordForm" onChange={(e)=>setpasswordForm(e.target.value)} value={passwordForm}/>
                                        <label htmlFor="passwordForm" className={passwordForm ? 'active' : ''}><h5>รหัสผ่าน</h5></label>
                                    </div>
                                </div>
                            </div>
         
                            <div className="row">
                                <div className="text-left col-md-6">
                                    <label htmlFor="LoginFormRemember" className="remeber"><input type="checkbox" id="LoginFormRemember" name="LoginFormRemember" onChange={inputChecked} defaultChecked={LoginFormRemember ? true :false}/> จดจำฉันไว้</label>
                                </div>
                                <div className="text-right col-md-6">
                                    {/* <Link to="/register">ลงทะเบียน</Link> */}
                                    <a href="#">ลืมรหัสผ่าน?</a>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="text-right col-md-12">
                                    <a href="#">ลืมรหัสผ่าน?</a>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="text-center col-md-12">
                                    <button type="submit" className={`${StyleLogin.btn_style} font-weight-bold`} id="btn_checklogin" ><span style={{fontSize: '12pt'}}>เข้าสู่ระบบ</span></button>

                                    <button type="button" className={`${StyleLogin.btn_style} font-weight-bold disabled`} id="btn_waitlogin" style={{display:'none'}}><span style={{fontSize: '12pt'}}><span className="mb-1 spinner-border spinner-border-sm" role="status" aria-hidden="true" disabled /> กำลังเข้าสู่ระบบ</span></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="text-center col-md-12">
                                    <span style={{fontSize: '16pt'}}>ระบบบริหารจัดภายในองค์กร</span> <br /> <span style={{fontSize: '10pt'}}>บริษัท ศักดิ์สยามลิสซิ่ง จำกัด (มหาชน)</span><br /><span style={{fontSize: '9pt'}}>พัฒนาโดย : ฝ่ายการตลาด (เว็บไซต์และบริหารงานลูกค้าออนไลน์)</span><br /><span style={{fontSize: '10pt'}}>© 2023 Copyright: Saksiam Leasing Public Company Limited. All Rights Reserved. (version 2.0.0)</span>
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        <img className={StyleLogin.wave_2} src={`${baseUrL}/assets/img/wave2.png`} alt=""></img>
        </LoginLayout>
    );
};

export default Login;
