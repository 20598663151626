import BackendLayout from "../../../components/layouts/backend/BackendLayout"
import productAPI from "../../../apiservices/productAPI"
import { useEffect, useState } from "react"


const FormTest = () => {
    // ส่วนการอ่านข้อมูลจาก API

    const [products,setProducts] = useState([])
    
    useEffect(()=>{
        //เรียก API ก่อนเลย เหมือน readData()
        productAPI.getAllProduct().then(res=>{
            //console.log(res.data)
            setProducts(res.data)
        })
    },[])

    return (
        <BackendLayout>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>รายการขออนุมัติรถไม่มีเรทจัด</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">หน้าหลัก</a></li>
                                    <li className="breadcrumb-item active">รายการขออนุมัติรถไม่มีเรทจัด</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="mt-2 row">
                                            <div className="mb-2 col-md-8"></div>
                                            <div className="mb-2 text-right col-md-1" />
                                            <div className="mb-2 text-right col-md-3" />
                                        </div>
                                    </div>
                                    <div className="p-0 card-body table-responsive fIN">
                                        <table className="table table-hover table-sm">
                                            <thead className="bg-primary">
                                                <tr className="">
                                                    <th className="text-center font-weight-bold th_num" scope="row">num</th>
                                                    <th className="text-center font-weight-bold">Img</th>
                                                    <th className="text-center font-weight-bold">Name</th>
                                                    <th className="text-center font-weight-bold">Barcode</th>
                                                    <th className="text-center font-weight-bold">Price</th>
                                                    <th className="text-center font-weight-bold">Date</th>
                                                    <th className="text-center font-weight-bold">Manage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    products.map((product, index) => (
                                                <tr key={index}>
                                                    <td className="text-center font-weight-bold">{index+1}</td>
                                                    <td className="text-center font-weight-bold">
                                                        <img src={product.product_image} height="50" alt="" />
                                                    </td>
                                                    <td className="font-weight-bold">
                                                        {product.product_name}
                                                    </td>
                                                    <td className="text-center font-weight-bold">
                                                        {product.product_barcode}
                                                    </td>
                                                    <td className="text-center font-weight-bold">
                                                        {product.product_price}
                                                    </td>
                                                    <td className="text-center font-weight-bold">6
                                                    
                                                    </td>
                                                    <td className="text-center font-weight-bold">
                                                        <button typebox="text" className="text-white btn btn-warning btn-sm" title="แก้ไขข้อมูล">แก้ไข</button><button typebox="text" className="text-white btn btn-danger btn-sm" title="ลบข้อมูล">ลบ</button>
                                                    </td>
                                                </tr>
                                                        )
                                                    )
                                                }   
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer">
                                        <div id="pagination" />
                                    </div>
                                </div>{/* /.card */}
                            </div>{/* /.col-md-12 */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </section>{/* /.section content */}
                {/* /.content-wrapper */}
            </div>
        </BackendLayout>
    )
}

export default FormTest