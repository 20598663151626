import DashboardLayout from "../../../components/layouts/backend/DashboardLayout"
import jwt_decode from "jwt-decode";

const Dashboard = () => {
  if(!localStorage.getItem('userToken')){
    window.location = '/'
  }else{
    let status_user = jwt_decode(localStorage.getItem('userToken'))[0].status_user
    let ID_personnel = jwt_decode(localStorage.getItem('userToken'))[0].ID_personnel
    let username = jwt_decode(localStorage.getItem('userToken'))[0].username
    let AcgU_code = jwt_decode(localStorage.getItem('userToken'))[0].AcgU_code
    let PST_code = jwt_decode(localStorage.getItem('userToken'))[0].PST_code
    let WP_code = jwt_decode(localStorage.getItem('userToken'))[0].WP_code
    
    if(status_user === '1' && ID_personnel && username && AcgU_code && PST_code && WP_code){
      return (
        <DashboardLayout>
          <div className="content-header">
                <div className="container-fluid">
                    <div className="mb-2 row">
                      <div className="col-sm-12">
                          <h1 className="m-0 text-dark font-weight-bold">ตัวอย่างการแสดงข้อมูล</h1>
                      </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
            </div>
        </DashboardLayout>
      )
    }else{
      window.location = '/'
    }
  }
}

export default Dashboard