
const FooterDB = () => {
  return (
    <>
        <footer className="main-footer text-center">
            {/* To the right */}
            <div className="float-right d-none d-sm-inline">
                <b>Version</b> 3.0.0
            </div>
            {/* Default to the left */}
            <strong>Copyright © 2022 <a href="http://saksiam.com" target="_back">Saksiam Leasing Public Company Limited.</a> All rights reserved. พัฒนาโดย : ฝ่ายการตลาด (ส่วนงานเว็บไซต์และงานบริหารลูกค้าออนไลน์)</strong> 
        </footer>
    </>
  )
}

export default FooterDB