import { useState } from 'react';
import { Link, NavLink, useLocation  } from 'react-router-dom';


const Navbar = () => {
  let location = useLocation();
  //console.log(location.pathname)

  return (
    <>
      <aside className="main-sidebar elevation-4 sidebar-dark-lightblue sidebar-no-expand">
        {/* Brand Logo */}
        <a href="../../index3.html" className="brand-link">
          <img
            src="../../dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">AdminLTE 3</span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src="../../dist/img/user2-160x160.jpg"
                className="img-circle elevation-2"
                alt="User Image"
              />
            </div>
            <div className="info">
              <a href="#" className="d-block">
                คุณปรีชา ทายะ
              </a>
            </div>
          </div>

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-flat nav-child-indent nav-compact"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link to="/backend/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>Dashboard</p>
                </Link>
              </li>
              
              <li className="nav-header">เมนูการจัดการ</li>

              
              <li className={`${location.pathname === '/backend/formtest' || location.pathname === '/backend/formtest2' ? 'menu-open' : ''} nav-item has-treeview`}>
                <a href="#" className={`${location.pathname === '/backend/formtest' || location.pathname === '/backend/formtest2' ? 'active' : ''} nav-link`}>
                  <i className="nav-icon far fa-file-alt" />
                  <p>
                    ฟอร์มอิเล็กทรอนิกส์
                    <i className="fas fa-angle-left right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/backend/formtest" className={`${location.pathname === '/backend/formtest' ? 'active' : ''} nav-link`}>
                      <i className="far fa-circle nav-icon" />
                      <p>ฟอร์มขออนุมัติรถไม่มีเรทจัด</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/backend/formtest2" className={`${location.pathname === '/backend/formtest2' ? 'active' : ''} nav-link`}>
                      <i className="far fa-circle nav-icon" />
                      <p>ฟอร์มแจ้งหักลดหย่อน</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/backend/formtest3" className={`${location.pathname === '/backend/formtest3' ? 'active' : ''} nav-link`}>
                      <i className="far fa-circle nav-icon" />
                      <p>ฟอร์มแจ้งขอหนังสือรับรอง</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink to="/backend/test" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>ทดสอบ</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <a href="../calendar.html" className="nav-link">
                  <i className="nav-icon far fa-calendar-alt" />
                  <p>
                    Calendar
                    <span className="badge badge-info right">2</span>
                  </p>
                </a>
              </li>
              <li className="nav-item">
                <a href="../kanban.html" className="nav-link">
                  <i className="nav-icon fas fa-columns" />
                  <p>Kanban Board</p>
                </a>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
};

export default Navbar;
