import Header from './include/Header'
import Navbar from './include/Navbar'
import Footer from './include/Footer'

const DashboardLayout = ({children}) => {
  return (
    <>
        <Header/>
        <Navbar/>
            {children}
        <Footer/>
    </>
  )
}

export default DashboardLayout