import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';


const Register = () => {
    //การเรียกใช้งาน React Hook Form
    //ต้องใช้  4 ตัวแปรนี้ register,handleSubmit,reset,errors เท่านั้น input ทุกตัวต้องมี name=""
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    //สร้างฟังก์ชั่นมารับข้อมูลจาก React hook form ไปใช้
    const registerForm = (data,e) => {
        console.log(data)

        // จำบันทีกข้อมูลก็ทำต่อในนี้เลย
        
    }

    return (
        <>
        <div className="container">
            <div className="row justify-content-center p-5">
                <div className="col-md-12">
                    <div className="register-box">
                        <div className="card card-outline card-primary">
                            <div className="card-header text-center">
                                <a href="../../index2.html" className="h1">
                                <b>ลงทะเบียน</b>
                                </a>
                            </div>
                            <div className="card-body">
                                <p className="login-box-msg">ลงทะเบียนการเข้าใช้ระบบสำหรับพนักงานใหม่</p>
                                <form method="post" onSubmit={handleSubmit(registerForm)}>
                                    <div className="row mb-2">
                                        <div className="col-md-10">
                                            <label htmlFor="fullname" className="h6">ชื่อ - สกุล :</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="กรอกชื่อ - สกุล" id="fullname" name="fullname" 
                                                {...register("fullname", { required: true})}
                                                style={{ border: errors.fullname ? '1px solid red' : '' }}
                                                />
                                            </div>
                                            {errors.fullname && errors.fullname.type === "required" && <div className="text-red mt-1">กรุณากรอกชื่อ - สกุล</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-10">
                                            <label htmlFor="email">อีเมล์ :</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="กรอกอีเมล์" id="email" name="email"
                                                {...register("email", { required: true , pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                },})}
                                                style={{ border: errors.email ? '1px solid red' : '' }}
                                                />
                                            </div>
                                            {errors.email && errors.email.type === "required" && <div className="text-red mt-1">กรุณากรอกอีเมล์</div>}
                                            {errors.email && errors.email.type === "pattern" && <div className="text-red mt-1">รูปแบบอีเมล์ ไม่ถูกต้อง</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-10">
                                            <label htmlFor="username" className="h6">Username :</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="กรอก Username" id="username" name="username" 
                                                {...register("username", { required: true})}
                                                style={{ border: errors.username ? '1px solid red' : '' }}
                                                />
                                            </div>
                                            {errors.username && errors.username.type === "required" && <div className="text-red mt-1">กรุณากรอก Username</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-10">
                                            <label htmlFor="password" className="h6">Password :</label>
                                            <div className="input-group">
                                                <input type="password" className="form-control" placeholder="กรอก Password" id="password" name="password" 
                                                {...register("password", { required: true})}
                                                style={{ border: errors.password ? '1px solid red' : '' }}
                                                />
                                            </div>
                                            {errors.password && errors.password.type === "required" && <div className="text-red mt-1">กรุณากรอก Password</div>}
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-10 text-center">
                                            <button type="submit" className="btn btn-primary btn-sm">ลงทะเบียน</button>
                                            <button type="button" className="btn btn-danger btn-sm" onClick={ ()=>{reset()} }>ล้างข้อมูล</button>
                                        </div>
                                    </div>
                                    <Link to="/login">กลับหน้า Login</Link>
                                </form>
                            </div>{/* /.form-box */}
                        </div>{/* /.card */}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Register;
