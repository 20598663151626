import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import Dashboard from "./pages/backend/dashboard/Dashboard";
import FormTest from "./pages/backend/forms/FormTest";
import FormTest2 from "./pages/backend/forms/FormTest2";
import Test from "./pages/backend/forms/Test";

import Login from "./pages/login/Login";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Register from "./pages/register/Register";



const routes = () => {
  return (
    // <Router basename={'/app'}>
    <Router>
      <Routes>
        {/* FrontEnd */}
        <Route path="/" exact={true} element={<Login/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/register" element={<Register/>}></Route>


    
        {/* BackEnd */}
        <Route path="/backend/dashboard" element={<Dashboard/>}></Route>
        <Route path="/backend/test" element={<Test/>}></Route>
        <Route path="/backend/formtest" element={<FormTest/>}></Route>
        <Route path="/backend/formtest2" element={<FormTest2/>}></Route>
      

        {/* 404 Page */}
        <Route path="*" element={<PageNotFound/>}></Route>
      </Routes>
    </Router>
  )
}

export default routes