import React from 'react';
import ReactDOM from 'react-dom';
import SetRoutes from './routes'
import './styles/custom.css'
import App from './App';



ReactDOM.render(
  <React.StrictMode>
      <SetRoutes/>
  </React.StrictMode>,
  document.getElementById('root')
);