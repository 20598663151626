const Footer = () => {
  return (
    <>
      <footer className="main-footer">
        <strong>Copyright © 2022 <a href="http://saksiam.com" target="_back">Saksiam Leasing Public Company Limited.</a>.</strong>
        All rights reserved. พัฒนาโดย : ฝ่ายการตลาด (ส่วนงานเว็บไซต์และงานบริหารลูกค้าออนไลน์)
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 3.0.0
        </div>
      </footer>

    </>
  );
};

export default Footer;
