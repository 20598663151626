import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Header = () => {

  let history = useNavigate()
  
  const logout = () => {
    // history('/')
    //window.location = process.env.PUBLIC_URL //กรณีอยู่บน server จริง
    window.location = '/' //กรณีอยู่บน localhost:3000
    const element = document.getElementsByClassName("modal-backdrop")[0];
    element.classList.remove("show");

    //รอ remove localStorage token หลัง logout
    localStorage.removeItem("userToken");
  }

  return (
    <>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand-md navbar-dark navbar-lightblue border-bottom-0 text-sm">
        <div className="container-fluid">
          <a href="../../index3.html" className="navbar-brand">
            <img src="../../assets/img/img_icon/logo saksiam59(150x150px).png" alt="SAKSIAM Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
            <span className="brand-text font-weight-light"> SAKSIAM ERP 3.0.0</span>
          </a>
          <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse order-3" id="navbarCollapse">
            {/* Left navbar links */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href={process.env.PUBLIC_URL+'/backend/formtest'} className="nav-link"><i className="far fa-file-alt"></i> ฟอร์มอิเล็กทรอนิกส์</a>
                {/* <Link to="/backend/formtest" className="nav-link"><i className="far fa-file-alt"></i> ฟอร์มอิเล็กทรอนิกส์</Link> */}
              </li>
              <li className="nav-item">
                <a href="index3.html" className="nav-link"><i className="fas fa-user-lock"></i> ผู้ดูแลระบบ</a>
              </li>
              <li className="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle"><i className="fas fa-folder-open"></i> เมนูสำหรับเจ้าหน้าที่</a>
                <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                  <li><a href="#" className="dropdown-item">Some action </a></li>
                  <li><a href="#" className="dropdown-item">Some other action</a></li>
                  <li className="dropdown-divider" />
                  {/* Level two dropdown*/}
                  <li className="dropdown-submenu dropdown-hover">
                    <a id="dropdownSubMenu2" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">Hover for action</a>
                    <ul aria-labelledby="dropdownSubMenu2" className="dropdown-menu border-0 shadow">
                      <li>
                        <a tabIndex={-1} href="#" className="dropdown-item">level 2</a>
                      </li>
                      {/* Level three dropdown*/}
                      <li className="dropdown-submenu">
                        <a id="dropdownSubMenu3" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">level 2</a>
                        <ul aria-labelledby="dropdownSubMenu3" className="dropdown-menu border-0 shadow">
                          <li><a href="#" className="dropdown-item">3rd level</a></li>
                          <li><a href="#" className="dropdown-item">3rd level</a></li>
                        </ul>
                      </li>
                      {/* End Level three */}
                      <li><a href="#" className="dropdown-item">level 2</a></li>
                      <li><a href="#" className="dropdown-item">level 2</a></li>
                    </ul>
                  </li>
                  {/* End Level two */}
                </ul>
              </li>
            </ul>
      
          </div>
          {/* Right navbar links */}
          <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {/* Messages Dropdown Menu */}
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="fas fa-comments" />
                <span className="badge badge-danger navbar-badge">3</span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <a href="#" className="dropdown-item">
                  {/* Message Start */}
                  <div className="media">
                    <img src="../../dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                    <div className="media-body">
                      <h3 className="dropdown-item-title">
                        Brad Diesel
                        <span className="float-right text-sm text-danger"><i className="fas fa-star" /></span>
                      </h3>
                      <p className="text-sm">Call me whenever you can...</p>
                      <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                    </div>
                  </div>
                  {/* Message End */}
                </a>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item">
                  {/* Message Start */}
                  <div className="media">
                    <img src="../../dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                    <div className="media-body">
                      <h3 className="dropdown-item-title">
                        John Pierce
                        <span className="float-right text-sm text-muted"><i className="fas fa-star" /></span>
                      </h3>
                      <p className="text-sm">I got your message bro</p>
                      <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                    </div>
                  </div>
                  {/* Message End */}
                </a>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item">
                  {/* Message Start */}
                  <div className="media">
                    <img src="../../dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
                    <div className="media-body">
                      <h3 className="dropdown-item-title">
                        Nora Silvester
                        <span className="float-right text-sm text-warning"><i className="fas fa-star" /></span>
                      </h3>
                      <p className="text-sm">The subject goes here</p>
                      <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
                    </div>
                  </div>
                  {/* Message End */}
                </a>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item dropdown-footer">See All Messages</a>
              </div>
            </li>
            {/* Notifications Dropdown Menu */}
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-bell" />
                <span className="badge badge-warning navbar-badge">15</span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-header">15 Notifications</span>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item">
                  <i className="fas fa-envelope mr-2" /> 4 new messages
                  <span className="float-right text-muted text-sm">3 mins</span>
                </a>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item">
                  <i className="fas fa-users mr-2" /> 8 friend requests
                  <span className="float-right text-muted text-sm">12 hours</span>
                </a>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item">
                  <i className="fas fa-file mr-2" /> 3 new reports
                  <span className="float-right text-muted text-sm">2 days</span>
                </a>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="fa fa-user fa-fw" /> <i className="fa fa-caret-down" />
              </a>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <a className="dropdown-item font-weight-bold" href="#" data-toggle="modal" data-target="#ChangepassModal"><i className="fa fa-lock fa-fw" /> เปลี่ยนรหัสผ่าน
                </a>
                <div className="dropdown-divider" />
                <a className="dropdown-item font-weight-bold" href="#" data-toggle="modal" data-target="#logoutModal"><i className="fas fa-sign-in-alt fa-fw" /> ออกจากระบบ
                </a>
              </div>
            </li>

          </ul>
        </div>
      </nav>
      {/* /.navbar */}

      {/* Logout Modal*/}
      <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title font-weight-bold" id="exampleModalLabel"><i className="fas fa-sign-out-alt" /> ออกจากระบบ</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body"><h6 className="text-red font-weight-bold">คุณต้องการออกจากระบบใช่หรือไม่ใช่ หากต้องการคลิก "ออกจากระบบ"</h6></div>
            <div className="modal-footer">
              <button className="btn btn-light btn-sm" type="button" data-dismiss="modal">ยกเลิก</button>
              <a className="btn btn-danger btn-sm" onClick={logout}>ออกจากระบบ</a>
            </div>
          </div>
        </div>
      </div>			
      {/*จบ Logout Modal*/}
    </>
  );
};
  
export default Header;