import axios from "axios";
import { BASE_URL_PATH_AUTH,BASE_URL_PATH,API_KEY } from "./Api_Url"

export default axios.create({
    //baseURL : BASE_URL_PATH,
    baseURL : BASE_URL_PATH,
    // headers : {
    //     'Content-Type': 'application/json',
    //     'X-API-KEY' : API_KEY,
    // }
})