import HeaderDB from './include/HeaderDB'
// import Navbar from './include/Navbar'
import FooterDB from './include/FooterDB'

const DashboardLayout = ({children}) => {
  return (
    <>
      <div className="hold-transition layout-top-nav">
        <div className="wrapper">
          <HeaderDB/>
            {children}
          <FooterDB/>
        </div> 
      </div>
      
    </>
  )
}

export default DashboardLayout